@each $color, $value in $theme-colors {
    #{".bg-#{$color}"} {
        background-color: $value !important;
    }
}

.bg-white {
    background-color: $white !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.bg-facebook {
    background-color: $facebook !important;
}
.bg-twitter {
    background-color: $twitter !important;
}
.bg-website {
    background-color: $website !important;
}
