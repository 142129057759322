// ********************* COLORS *********************

// BILLPOCKET COLORS
$bp-blue: #17A5DF;
$bp-green: #21B6A0;
$bp-orange: #F59D01;
$bp-purple: #6C81F9;


// BILLPOCKET CONTRAST COLORS
$bp-blue-contrast-color: #037DBA;
$bp-blue-contrast-orange: #E12D05;
$bp-green-contrast-color: #06847D;
$bp-purple-contrast-color: #4B65F7;

// BILLPOCKET THEMING
$primary:  $bp-blue !default;
$secondary: transparentize(#000000, 0.25) !default;
$success:   #22B69F !default;
$info:      #007BFF !default;
$warning:   #FF9E18 !default;
$danger:    #FF0033 !default;
$light:     #f8f9fa !default;
$dark:      #343a40 !default;

$login-color: #4a72b0;
$sign-in-color: #4595f4;
$primary-light-color: #e4f7fe;
$accent-color: #00C3CB;

$bp-body-color: #ECEFF1;
$bp-purple-color: #6C81F9;
$bg-sidenav-color: #263238;
$border-color: #C2C4CC;
$icon-secondary-color: #AEB1BA;
$text-color: #646569;



// SOCIAL MEDIA COLORS
$facebook: #3b5998;
$google: #4285F4;
$twitter: #55acee;
$website: #dd4b39;

// NEUTRAL COLORS
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #F2F3F4 !default;
$gray-400: #E6E7E9 !default;
$gray-500: #D1D2D4 !default;
$gray-600: #65666A !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #2B3038 !default;
$black:    #000000 !default;


// ANGULAR THEME COLORS
$bp-primary: (
    50 : #e1eff7,
    100 : #b3d8ea,
    200 : #81bedd,
    300 : #4fa4cf,
    400 : #2991c4,
    500 : #037dba,
    600 : #0375b3,
    700 : #026aab,
    800 : #0260a3,
    900 : #014d94,
    A100 : #bfdcff,
    A200 : #8cc0ff,
    A400 : #59a4ff,
    A700 : #4096ff,
    contrast: (
        50 : $secondary,
        100 : $secondary,
        200 : $secondary,
        300 : $secondary,
        400 : white,
        500 : white,
        600 : white,
        700 : white,
        800 : white,
        900 : white,
        A100 : $secondary,
        A200 : $secondary,
        A400 : $secondary,
        A700 : $secondary,
    )
);

$bp-accent: (
    50 : #fdf7ee,
    100 : #fbecd4,
    200 : #f8dfb8,
    300 : #f5d29c,
    400 : #f2c986,
    500 : #f0bf71,
    600 : #eeb969,
    700 : #ecb15e,
    800 : #e9a954,
    900 : #e59b42,
    A100 : white,
    A200 : white,
    A400 : #ffe9d0,
    A700 : #ffddb7,
  contrast: (
    50: $secondary,
    100: $secondary,
    200: $secondary,
    300: $secondary,
    400: $secondary,
    500: $secondary,
    600: $secondary,
    700: $secondary,
    800: $secondary,
    900: $secondary,
    A100: $secondary,
    A200: $secondary,
    A400: $secondary,
    A700: $secondary
  )
);

$bp-warn: (
  50: #feeeef,
  100: #fed5d8,
  200: #fdbdc0,
  300: #fca4a9,
  400: #fb8c91,
  500: #fa737a,
  600: #f95a63,
  700: #f8424b,
  800: #f72934,
  900: #f6101c,
  A100: white,
  A200: white,
  A400: white,
  A700: #e50814,
  contrast: (
    50: $secondary,
    100: $secondary,
    200: $secondary,
    300: $secondary,
    400: $secondary,
    500: $secondary,
    600: $secondary,
    700: $secondary,
    800: $secondary,
    900: $secondary,
    A100: $secondary,
    A200: $secondary,
    A400: $secondary,
    A700: $secondary
  )
);

// Billpocket UI COLORS
$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary":    $bp-blue-contrast-color,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);


$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

// ********************* CONFIG *********************
$enable-rounded:       true !default;
$enable-shadows:                              false !default;


$border-width:         2px !default;
$border-color:         $gray-300 !default;

$border-radius:        .25rem !default;
$border-radius-lg:     .3rem !default;
$border-radius-sm:     .2rem !default;

// ********************* SIZES *********************
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;


// Spacing
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3)
  ),
  $spacers
);

// ********************* TIPOGRAPHY *********************
$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

// Links
//
// Style anchor elements.

$link-color:                              theme-color("primary") !default;
$link-decoration:                         none !default;
$link-hover-color:                        darken($link-color, 15%) !default;
$link-hover-decoration:                   underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;


// IMAGES
// Image thumbnails

$thumbnail-padding:                 .25rem !default;
$thumbnail-bg:                      $white !default;
$thumbnail-border-width:            $border-width !default;
$thumbnail-border-color:            $gray-300 !default;
$thumbnail-border-radius:           $border-radius !default;
$thumbnail-box-shadow:              0 1px 2px rgba($black, .075) !default;


// Figures

$figure-caption-font-size:          90% !default;
$figure-caption-color:              $gray-600 !default;
