@import '~@angular/material/theming';

$bp-typography: mat-typography-config(
    $font-family: "'Montserrat', sans-serif"
);

$bp-light-foreground: (
  base:              $secondary,
  divider:           $primary,
  dividers:          $primary,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba(black, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         black,
  hint-text:         $dark-disabled-text,
  secondary-text:    $dark-secondary-text,
  icon:              rgba(black, 0.54),
  icons:             rgba(black, 0.54),
  text:              rgba($secondary, 0.87),
  slider-min:        rgba(black, 0.87),
  slider-off:        rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

$primary-palete: mat-palette($bp-primary);
$bp-accent-palete: mat-palette($bp-accent);
$bp-warn-palete: mat-palette($bp-warn);

$bp-main-theme: mat-light-theme(
  $primary-palete,
  $bp-accent-palete,
  $bp-warn-palete
);

$bp-dark-theme: mat-dark-theme(
  $primary-palete,
  $bp-accent-palete,
  $bp-warn-palete
);

*:not(.fa, strong, b) {
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 600 !important;
}

button.mat-button,
button.mat-raised-button,
button.mat-flat-button,
button.mat-stroked-button {
    border-radius: 40px !important;
}

.table-responsive {
    overflow-x: auto;
    .mat-table {
        width: 100%;
        td, th {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}

.active {
    background-color: transparentize($primary, 0.8);
}

.mat-card-header-text {
    margin: 0 !important;
}

.dark-loader-overlay {
    background-color: rgba(0,0,0,0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 100000 !important;
}

.btn-facebook, .btn-google {
    color: white !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    padding-left: 4px !important;
    &::before {
        content: '';
        background-size: cover;
        margin-right: 4px;
        min-height: 30px;
        min-width: 30px;
    }
    span.mat-button-wrapper {
        margin: auto !important;
    }
}

.btn-google {
    background-color: $google !important;
    &::before {
        background-image: url('../billpocket.ui/images/btn_google.png');
    }
}


.btn-facebook {
    background-color: $facebook !important;
    &::before {
        background-image: url('../billpocket.ui/images/btn_facebook.png');
    }
}

.mat-snack-bar-container {
    white-space: pre-wrap;
    min-width: 265px !important;
    &.bg-warning {
        color: darken($warning, 30%) !important;
        .mat-button-base {
            color: darken($warning, 30%) !important;
        }
    }
    &.bg-danger {
        color: darken($danger, 30%) !important;
        .mat-button-base {
            color: darken($danger, 30%) !important;
        }
    }
    &.bg-success {
        color: darken($success, 30%) !important;
        .mat-button-base {
            color: darken($success, 30%) !important;
        }
    }
}

html, body { height: 100%; }

.bp-light-theme {
    $bp-app-theme-custom: map-merge($bp-main-theme, (foreground: $bp-light-foreground));
    @include angular-material-theme($bp-app-theme-custom);
    mat-sidenav mat-toolbar, .sidenav, .sidenav-mini {
        background-color: $bg-sidenav-color !important;
    }
    .sidenav {
        .bg-route-active {
            background-color: darken($bg-sidenav-color, 5%);
        }
        .mat-list-item {
            &:hover, &:focus {
                background-color: darken($bg-sidenav-color, 30%);
            }
        }
    }
    .mat-table-action {
        .mat-row {
            &:hover, &:focus {
                background-color: $dark-focused !important;
                cursor: pointer;
            }
            &:active {
                background-color: map_get($mat-grey, 300) !important;
            }
        }
    }
}

.bp-dark-theme {
    @include angular-material-theme($bp-dark-theme);
    mat-sidenav mat-toolbar, .sidenav, .sidenav-mini {
        background-color: $gray-800;
    }
    .sidenav {
        .bg-route-active {
            background-color: lighten($gray-800, 5%) !important;
        }
        .mat-list-item {
            &:hover, &:focus {
                background-color: lighten($gray-800, 30%) !important;
            }
        }
    }
    .mat-table-action {
        .mat-row {
            &:hover, &:focus {
                background-color: $light-focused !important;
                cursor: pointer;
            }
            &:active {
                background-color: map_get($mat-grey, 900) !important;
            }
        }
    }
    .mat-list-item-disabled {
        background-color: transparentize($black , 0.8);
    }
}
@include mat-core($bp-typography);
