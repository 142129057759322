// Image Mixins
// - Responsive image

// Responsive image
//
// Keep images from scaling beyond the width of their parents.

@mixin img-fluid() {
  max-width: 100%;
  height: auto;
}
