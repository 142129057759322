@import 'assets/billpocket.ui/billpocket-ui';
@import 'assets/billpocket.ui/billpocket-angular-theme.scss';

html, body { height: 100%; }
body { margin: 0; font-family: 'Montserrat', sans-serif !important; }

@keyframes fadeInDown {
    from {
      transform: translateY(-60px);
      opacity: 0;
    } to {
      transform: translateY(0);
      opacity: 1;
    }
}

.grecaptcha-badge { visibility: hidden; }

.mat-dialog-weight {
    @include media-breakpoint-up("xs") {
        width: 95% !important;
    }
    @include media-breakpoint-up("sm") {
        width: 530px!important;
    }
}

.mat-card-title {
    margin-bottom: 1em !important;
    margin-top: 0.5em !important;
}

a {
    cursor: pointer !important;
}
